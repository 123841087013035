export default [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'contract IERC20', name: 'rewardToken', type: 'address' },
      { indexed: true, internalType: 'address', name: 'claimer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'batchId', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'epochTimestamp', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'tag', type: 'string' },
    ],
    name: 'ClaimAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'batchId', type: 'uint256' }],
    name: 'ClaimApproved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract IERC20', name: 'rewardToken', type: 'address' },
      { indexed: true, internalType: 'address', name: 'claimer', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'batchId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'Claimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'whitelisted', type: 'bool' },
    ],
    name: 'WhitelistAddressSet',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'amountToClaim',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'batchIds', type: 'uint256[]' },
      { internalType: 'bool', name: 'approve', type: 'bool' },
    ],
    name: 'approveClaims',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'batchApprovals',
    outputs: [
      { internalType: 'contract IERC20', name: 'token', type: 'address' },
      { internalType: 'bool', name: 'approved', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'claimList', type: 'uint256[]' }],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'batchIds', type: 'uint256[]' },
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'contract IERC20', name: 'token', type: 'address' },
    ],
    name: 'getClaimableAmountForUser',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'batchIds', type: 'uint256[]' },
      { internalType: 'address', name: 'user', type: 'address' },
    ],
    name: 'getClaimableIdsForUser',
    outputs: [{ internalType: 'uint256[]', name: 'claimableIds', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'contract IERC20', name: '', type: 'address' },
    ],
    name: 'totalClaimed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'whitelisted',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const
