import { TradeSettledPublicResponseSchema } from '@lyra/core/api/types/public.get_trade_history'

import { LAST_DISTRIBUTED_REWARDS_WEEK, RewardEvent, RewardToken } from '../constants/rewards'

export const formatRewardEventName = (event: RewardEvent): string => {
  switch (event.type) {
    case 'trading':
      return `Trading Week ${event.week}`
    case 'frame-dice-roll':
      return 'Farcaster Dice Roll'
    case 'frame-pfp':
      return 'Farcaster "Go Green"'
    case 'affiliate':
      return `Affiliates Month ${event.month}`
    case 'alpha-trading':
      return `Alpha Trading Week ${event.week}`
    case 'custom':
      return 'Custom Event'
  }
}

export function calculateAverageRewardsPerUser(
  rewardEventsMap: Record<string, RewardEvent[]>
): number {
  const totalRewardsPerUser = Object.values(rewardEventsMap)
    .flat()
    .reduce(
      (acc, event) => {
        acc[event.address] = (acc[event.address] || 0) + event.rewards
        return acc
      },
      {} as Record<string, number>
    )

  const userRewards = Object.values(totalRewardsPerUser)

  if (userRewards.length === 0) return 0

  const sum = userRewards.reduce((acc, val) => acc + val, 0)
  return sum / userRewards.length
}

export function calculateMedianRewardsPerUser(
  rewardEventsMap: Record<string, RewardEvent[]>
): number {
  const totalRewardsPerUser = Object.values(rewardEventsMap)
    .flat()
    .reduce(
      (acc, event) => {
        acc[event.address] = (acc[event.address] || 0) + event.rewards
        return acc
      },
      {} as Record<string, number>
    )

  const userRewards = Object.values(totalRewardsPerUser).sort((a, b) => a - b)

  if (userRewards.length === 0) return 0

  const midIndex = Math.floor(userRewards.length / 2)
  if (userRewards.length % 2 === 0) {
    return (userRewards[midIndex - 1] + userRewards[midIndex]) / 2
  } else {
    return userRewards[midIndex]
  }
}

export function calculateAverageFeesPerUser(trades: TradeSettledPublicResponseSchema[]): number {
  const totalFeesPerUser = trades.reduce(
    (acc, trade) => {
      const address = trade.wallet
      const fee = +trade.trade_fee
      acc[address] = (acc[address] || 0) + fee
      return acc
    },
    {} as Record<string, number>
  )

  const userFees = Object.values(totalFeesPerUser)

  if (userFees.length === 0) return 0

  const sum = userFees.reduce((acc, val) => acc + val, 0)
  return sum / userFees.length
}

export function calculateMedianFeesPerUser(trades: TradeSettledPublicResponseSchema[]): number {
  const totalFeesPerUser = trades.reduce(
    (acc, trade) => {
      const address = trade.wallet
      const fee = +trade.trade_fee
      acc[address] = (acc[address] || 0) + fee
      return acc
    },
    {} as Record<string, number>
  )

  const userFees = Object.values(totalFeesPerUser).sort((a, b) => a - b)

  if (userFees.length === 0) return 0

  const midIndex = Math.floor(userFees.length / 2)
  if (userFees.length % 2 === 0) {
    return (userFees[midIndex - 1] + userFees[midIndex]) / 2
  } else {
    return userFees[midIndex]
  }
}

export function getPendingTradingRewards(rewardEvents: RewardEvent[]) {
  return rewardEvents
    .filter(
      (e) =>
        e.token === RewardToken.LYRA &&
        (e.type === 'trading' || e.type === 'alpha-trading') &&
        e.endsAt > Date.now()
    )
    .reduce((sum, reward) => sum + reward.rewards, 0)
}

export function getPreDistributionEpochLyraRewards(rewardEvents: RewardEvent[]) {
  return rewardEvents
    .filter(
      (e) =>
        (e.type === 'trading' || e.type === 'alpha-trading') &&
        e.token === RewardToken.LYRA &&
        // Up to Week 11
        e.week <= LAST_DISTRIBUTED_REWARDS_WEEK
    )
    .reduce((sum, reward) => sum + reward.rewards, 0)
}

export function getPostDistributionEpochLyraRewards(rewardEvents: RewardEvent[]) {
  return rewardEvents
    .filter(
      (e) =>
        (e.type === 'trading' || e.type === 'alpha-trading') &&
        e.token === RewardToken.LYRA &&
        // Week 12 onwards
        e.week > LAST_DISTRIBUTED_REWARDS_WEEK &&
        // Has ended
        e.endsAt <= Date.now()
    )
    .reduce((sum, reward) => sum + reward.rewards, 0)
}
